import React, { useEffect } from 'react';
import Footer from 'src/components/Footer';
import PageHeader from 'src/components/PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { fetcher } from 'src/utils/fetcher';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const MakkahYardDashboard = () => {
  const { data, error, isLoading, mutate } = useSWR(
    `/api/dashboard/stats`,
    fetcher,
    {
      revalidateOnFocus: false
    }
  );

  const { t } = useTranslation();
  const theme = useTheme();

  const chartOptions = {
    stroke: {
      curve: 'smooth',
      width: [0, 5]
    },
    theme: {
      mode: theme.palette.mode
    },
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      }
    },
    markers: {
      hover: {
        sizeOffset: 2
      },
      shape: 'circle',
      size: 6,
      strokeWidth: 3,
      strokeOpacity: 1,
      strokeColors: theme.colors.alpha.white[100],
      colors: [theme.colors.error.main]
    },
    colors: [theme.colors.primary.main, theme.colors.error.main],
    fill: {
      opacity: 1
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 8,
        columnWidth: '25%'
      }
    },
    labels: data?.dailyAccounts?.map((item) =>
      new Date(item.createdDate).toLocaleString('en-US', {
        day: '2-digit',
        month: 'short'
      })
    ),
    dataLabels: {
      enabled: false
    },
    grid: {
      strokeDashArray: 5,
      borderColor: theme.palette.divider
    },
    legend: {
      show: false
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      tickAmount: 6,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  };
  const chartOptions2 = {
    stroke: {
      curve: 'smooth',
      width: [0, 5]
    },
    theme: {
      mode: theme.palette.mode
    },
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      }
    },
    markers: {
      hover: {
        sizeOffset: 2
      },
      shape: 'circle',
      size: 6,
      strokeWidth: 3,
      strokeOpacity: 1,
      strokeColors: theme.colors.alpha.white[100],
      colors: [theme.colors.error.main]
    },
    colors: [theme.colors.primary.main, theme.colors.error.main],
    fill: {
      opacity: 1
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        columnWidth: '25%'
      }
    },
    labels: data?.dailyQrCodes?.map((item) =>
      new Date(item.createdDate).toLocaleString('en-US', {
        day: '2-digit',
        month: 'short'
      })
    ),
    dataLabels: {
      enabled: false
    },
    grid: {
      strokeDashArray: 5,
      borderColor: theme.palette.divider
    },
    legend: {
      show: false
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      tickAmount: 6,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  };

  const chartData = [
    {
      name: 'Number Of Account',
      type: 'column',
      data: data?.dailyAccounts?.map((item) => item.numberofAccount)
    }
  ];
  const chartData2 = [
    {
      name: 'Number Of Account',
      type: 'column',
      data: data?.dailyQrCodes?.map((item) => item.numberofAccount)
    }
  ];

  return (
    <>
      <PageTitleWrapper>
        <PageHeader
          title={'Dashboard'}
          subtitle={
            'All aspects related to the Dashboard managed from this page'
          }
        />
      </PageTitleWrapper>
      <Card sx={{ m: 4 }}>
        <CardHeader sx={{ p: 3 }} title={t('Daily Accounts')} />
        <Divider
          sx={{
            display: { xs: 'none', sm: 'flex' }
          }}
        />
        <CardContent>
          <Chart
            options={chartOptions}
            series={chartData}
            type="line"
            height={306}
          />
        </CardContent>
      </Card>
      <Card sx={{ m: 4 }}>
        <CardHeader sx={{ p: 3 }} title={t('Daily Qr Codes')} />
        <Divider
          sx={{
            display: { xs: 'none', sm: 'flex' }
          }}
        />
        <CardContent>
          <Chart
            options={chartOptions2}
            series={chartData2}
            type="line"
            height={306}
          />
        </CardContent>
      </Card>

      <Footer />
    </>
  );
};

export default MakkahYardDashboard;
