import axios from 'axios';
import Snackbar from './Snackbar';

// @ts-ignore


// const getToken = () => store.getState().auth?.user?.token;
// console.log("🚀 ~ file: network.ts:6 ~ getToken", getToken())
// /**
//  * class for for network client layer
//  * @counterDownload handle dispose stack limit request download
//  * @counterUpload handle dispose stack limit request upload
//  * @class Api
//  */


const BASE_URL = 'http://localhost:5101'
class Api {

    constructor() {
        this.counterDownload = 0;
        this.counterUpload = 0;
        this.uniqueRequest = {};
        this.network = this.setupInterceptors();
    }
    counterDownload;
    counterUpload;
    uniqueRequest;
    network;
    /**
     * setup member axios
     *
     * @returns
     * @memberof Api
     */
    setupInterceptors() {
        const api = axios.create({});

        api.interceptors.request.use(
            (config) => {
                const auth = localStorage.getItem('user');
                const user = JSON.parse(auth);
                console.log("🚀 ~ file: axios.js:43 ~ Api ~ setupInterceptors ~ user:", user)
                if (user) {
                    console.log("Users")
                    config.headers['Authorization'] = `Bearer ${user.accessToken}`;
                }
                return config;
            },
            error => {
                console.log('Error Request >>>', error);
                // Do something with response error
                return Promise.reject(error);
            },
        );

        api.interceptors.response.use(
            response => {
                console.log('After Request >>>', response);
                // process more after response
                return response;
            },
            error => {
                console.log('Error Response >>>', error);
                // process more when exeption
                return Promise.reject(error);
            },
        );
        return api;
    }

    /**
     * Cancel all request
     *
     * @memberof Api
     */
    cancelAllRequest() {
        this.uniqueRequest.forEach((item) => { });
    }

    /**
     * Cancel one request
     *
     * @param {*} key
     * @memberof Api
     */
    cancelRequest(key) {
        if (this.uniqueRequest?.[key]) {
            console.log('Cancel request key', key);
            this.uniqueRequest[key]?.cancel();
        }
    }

    /**
     *
     * remove one request
     * @param {*} key
     * @memberof Api
     */
    removeTokenRequest(key) {
        if (this.uniqueRequest?.[key]) {
            console.log('Remove uniqueRequest request key', key);
            this.uniqueRequest[key] = null;
        }
    }

    /**
     * Get Mothod
     *
     * @param {*} endPoint
     * @param {*} [params={}]
     * @param {*} [headers={}]
     * @param {*} uniqueRequest
     * @param {string} [responseType='json']
     * @returns
     * @memberof Api
     */
    async get(endPoint, { params, headers, uniqueRequest, responseType }) {
        console.log(process.env.REACT_APP_API_URL);
        let cancelToken;
        if (uniqueRequest) {
            this.cancelRequest(uniqueRequest);
            cancelToken = axios.CancelToken.source();
            this.uniqueRequest[uniqueRequest] = cancelToken;
        }
        try {
            const response = await this.network({
                method: 'get',
                url: process.env.REACT_APP_API_URL + endPoint,
            });
            this.removeTokenRequest(uniqueRequest);
            if (response.data.message) {
            }
            return Promise.resolve(response.data);
        } catch (error) {
            this.removeTokenRequest(uniqueRequest);
            return Promise.reject(error);
        }
    }

    /**
     * Post Method
     *
     * @param {*} endPoint
     * @param {*} [payload={}]
     * @param {*} [headers={}]
     * @param {*} uniqueRequest
     * @returns
     * @memberof Api
     */
    async post(endPoint, { params, headers, uniqueRequest }) {
    console.log("🚀 ~ file: axios.js:161 ~ Api ~ post ~ endPoint:", endPoint)

        let cancelToken;
        if (uniqueRequest) {
            this.cancelRequest(uniqueRequest);
            cancelToken = axios.CancelToken.source();
            this.uniqueRequest[uniqueRequest] = cancelToken;
        }
        return this.network({
            method: 'post',
            url:  process.env.REACT_APP_API_URL + endPoint,
            data: params ?? {},
            headers: headers ?? {},
            cancelToken: cancelToken?.token,
        })
            .then((response) => {
                this.removeTokenRequest(uniqueRequest);
                if(response?.data?.success) {
                  Snackbar.success(response?.data?.message);
                  // SnackBar.(err.message)
                } else if(!response?.data?.success) {
                  Snackbar.error(response?.data?.message);
                }

                return Promise.resolve(response.data);
            })
            .catch((error) => {
                this.removeTokenRequest(uniqueRequest);
                return Promise.reject(error);
            });
    }
}

const _API = new Api();

export default _API;
