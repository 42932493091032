import Authenticated from 'src/components/Authenticated';
import { Suspense, lazy } from 'react';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import accountRoutes from './account';

import SuspenseLoader from 'src/components/SuspenseLoader';
import MakkahYardDashboard from 'src/content/dashboards/MakkahYardDashboard';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Dashboard = Loader(
  lazy(() => import('src/content/dashboards/MakkahYardDashboard'))
);
const Calendar = Loader(
  lazy(() => import('src/content/applications/Calendar'))
);
const ContactUs = Loader(
  lazy(() => import('src/content/dashboards/Reports/contactUs'))
);
const UserContactUs = Loader(
  lazy(() => import('src/content/UserPannel/contactUs'))
);
const Orders = Loader(
  lazy(() => import('src/content/dashboards/Reports/orders'))
);
const UserManagement = Loader(lazy(() => import('src/content/UserManagement')));
const EventManagement = Loader(
  lazy(() => import('src/content/EventManagement'))
);
const UserEventManagement = Loader(
  lazy(() => import('src/content/UserPannel/EventManagement'))
);
const SingleMember = Loader(lazy(() => import('src/content/dependent')));
const Membership = Loader(lazy(() => import('src/content/membership')));
const UserMembership = Loader(lazy(() => import('src/content/UserPannel/membership')));
const Appointment = Loader(lazy(() => import('src/content/appointment')));
const AdminRoutes = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <MakkahYardDashboard />
      }
    ]
  },
  {
    path: 'Reports',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: 'contact-us',
        element: <ContactUs />
      },
      {
        path: 'orders',
        element: <Orders />
      }
    ]
  },
  {
    path: 'appointments',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: 'list',
        element: <Appointment />
      },
      {
        path: 'calendar',
        element: <Calendar />
      }
    ]
  },
  {
    path: 'membership',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Membership />
      },
     
    ]
  },
  {
    path: 'user-management',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <UserManagement />
      }
    ]
  },
  {
    path: 'event-management',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <EventManagement />
      }
    ]
  }
];
const UserRoutes = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <MakkahYardDashboard />
      }
    ]
  },
  {
    path: 'Reports',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: 'contact-us',
        element: <UserContactUs />
      }
    ]
  },
  {
    path: 'appointments',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: 'list',
        element: <Appointment />
      },
      {
        path: 'calendar',
        element: <Calendar />
      }
    ]
  },
  {
    path: 'membership',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <UserMembership />
      },
     
    ]
  },
  
  {
    path: 'event-management',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <UserEventManagement />
      }
    ]
  }
];

const Not = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: []
  }
];

export { AdminRoutes, UserRoutes, Not };
