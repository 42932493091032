import { Box, Card, styled, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FooterWrapper = styled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        p={4}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            onClick={() => {
              navigate('/ ');
            }}
          >
            <Box sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}>
              &copy; 2024 - Makkah Yard
            </Box>
          </Typography>
        </Box>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
