import { useTranslation } from 'react-i18next';

import { Grid, Typography, Button, Box, CircularProgress } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

function PageHeader({ title, subtitle, buttontext, onPressButton, renderButtons, renderBackBtn, btnSubmitting }) {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {renderBackBtn && renderBackBtn()}
          <Box>
            <Typography variant="h3" component="h3" gutterBottom>
              {t(title)}
            </Typography>
            <Typography variant="subtitle2">
              {t(subtitle)}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        {
          buttontext &&
        
        <Button
          onClick={onPressButton}
          sx={{
            mt: { xs: 2, sm: 0 }
          }}
          
          variant="contained"
          startIcon={btnSubmitting ? <CircularProgress  size="1rem" /> : <AddTwoToneIcon fontSize="small" />}
        >
          {t(buttontext)}
        </Button>
        }
        {
          renderButtons && renderButtons()
        }
      </Grid>
    </Grid>
  );
}

export default PageHeader;
