import { useRoutes } from 'react-router-dom';
import { AdminRoutes, Not, UserRoutes } from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';
// import {  SnackbarUtilsConfigurator } from 'src/utils/SnackBar'
import { SnackbarUtilsConfigurator } from 'src/utils/Snackbar';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';

function App() {
  const { isInitialized } = useAuth();
  const auth = localStorage.getItem('user');
  const user = JSON.parse(auth);
  
  const content = useRoutes(
    user?.role == 'Admin'
      ? AdminRoutes
      : user?.role == 'Users'
      ? UserRoutes
      : Not
  );

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <SnackbarUtilsConfigurator />
          <CssBaseline />
          {isInitialized ? content : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
