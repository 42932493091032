import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import DashboardIcon from '@mui/icons-material/Dashboard';

const AdminItems = [
  {
    items: [
      {
        name: 'Dashboard',
        icon: DashboardIcon,
        link: '/'
      }
    ]
  },
  {
    heading: 'Reports',
    items: [
      {
        name: 'Contact Us',
        icon: BackupTableTwoToneIcon,
        link: '/Reports/contact-us'
      },

      {
        name: 'Orders',
        icon: BackupTableTwoToneIcon,
        link: '/Reports/orders'
      }
    ]
  },
  {
    heading: 'Appoitments',
    items: [
      {
        name: 'Appointment List',
        icon: BackupTableTwoToneIcon,
        link: '/appointments/list'
      },
      {
        name: 'Appointment Calendar',
        icon: BackupTableTwoToneIcon,
        link: '/appointments/calendar'
      }
    ]
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'MemberShip',
        link: '/membership'
      },
      {
        name: 'User Management',
        link: '/user-management'
      },
      {
        name: 'Event Management',
        link: '/event-management'
      }
    ]
  }
];
const UserItems = [
  {
    items: [
      {
        name: 'Dashboard',
        icon: DashboardIcon,
        link: '/'
      }
    ]
  },
  {
    heading: 'Reports',
    items: [
      {
        name: 'Contact Us',
        icon: BackupTableTwoToneIcon,
        link: '/Reports/contact-us'
      }
    ]
  },
  {
    heading: 'Appoitments',
    items: [
      {
        name: 'Appointment List',
        icon: BackupTableTwoToneIcon,
        link: '/appointments/list'
      },
      {
        name: 'Appointment Calendar',
        icon: BackupTableTwoToneIcon,
        link: '/appointments/calendar'
      }
    ]
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'MemberShip',
        link: '/membership'
      },
      {
        name: 'Event Management',
        link: '/event-management'
      }
    ]
  }
];
export { AdminItems, UserItems };
